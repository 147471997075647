@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --lightGray: #b5b5b5;
  --white: #FFF;
  --darkGray: #222222;
  --mediumGray: #4A4A4A; 
  --gray: #2D2D2D;
  --black : #000;
  --green: #24dd17;
  --yellow: #FFEB00;
  --blue: #278BC9;
  --blueishGray: #e9ebf5;
  --blueishWhite: #E6E6E6;
  --greenToYellowGradient: linear-gradient(to bottom, #24dd17, #FFEB00);
  --lowOpacityGreen: rgba(39, 201, 39, 0.2);
  --highOpacityGreen: rgba(39, 201, 39, 0.8);
  --extremeLowOpacityBlack: rgba(0, 0, 0, 0.2);
  --mediumOpacityWhite: rgba(255, 255, 255, 0.4);
  --lowOpacityWhite: rgba(255, 255, 255, 0.2);
}

@font-face {
  font-family: 'Din Condensed';
  src: url('../../public/fonts/DIN-Bold.ttf') format('truetype');
}


body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

.gradient-glowing-green-text {
  background: linear-gradient(to bottom, var(--green), var(--yellow));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: var(--highOpacityGreen) 0px 0px 20px;
}

.home-bg-video-overlay {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
}

.arrow-icon {
  transition: all 200ms ease-in-out;
}

.home-white-button:hover .arrow-icon {
  transform: translateX(5px) ;
}

@media(min-width: 1920px) {
  .main-section-text {
    width: 95%;
  }

  #home-bg-video {
    left: 0px;
    min-width: 2500px;
  }
}

@media(min-width: 2500px) {
  #home-bg-video {
    min-width: 3000px;
  }
}

@media(min-width: 3000px) {
  #home-bg-video {
    left: -500px;
    min-width: 4000px;
  }
}